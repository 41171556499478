import PropertyList from './PropertyList.js';

class MessageContent extends PropertyList
{
	constructor (message_uuid, obj)
	{
		super();

		this.message_uuid = ko.observable(message_uuid);
		this.content_nr = ko.observable();
		this.content_id = ko.observable();
		this.name = ko.observable();
		this.role = ko.observable();
		this.body = ko.observable();
		this.type = ko.observable();
		this.subtype = ko.observable();

		if (obj)
			this.set(obj);
	}

	async set (obj, fetchBody = true)
	{
		if (obj.hasOwnProperty('content_nr'))
			this.content_nr(obj.content_nr);
		if (obj.hasOwnProperty('name'))
			this.name(obj.name);
		if (obj.hasOwnProperty('property'))
		{
			await super.set(obj.property);
			if (super.getPropertyValue('type'))
				this.type (super.getPropertyValue('type'));
			if (super.getPropertyValue('subtype'))
				this.subtype (super.getPropertyValue('subtype'));
			if (super.getPropertyValue('content_id'))
				this.content_id (super.getPropertyValue('content_id'));
		}
		if (obj.hasOwnProperty('role'))
			this.role(obj.role);

		if (fetchBody)
			return await this.fetchBody();
		else 
			return [];
	}

	set_message_uuid (message_uuid)
	{
		this.message_uuid(message_uuid);
	}

	/**
	 * Get Response object for content
	 */
	async fetchBody ()
	{
		let data = '';
		try
		{
			if (!this.message_uuid())
				return;

			const result = await Grape.fetches.fetch(`/api/messages/message/content?message_uuid=${this.message_uuid()}&name=${this.name()}`);

			if (this.type() === 'text')
				data = await result.text();
			else
				data = await result.blob();

			this.body(data || '');

			return data;
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			return data;
		}
	}

	setBody (data)
	{
		this.body(data.body || data || '');
	}

	async saveBody ()
	{
		if (!this.type() || !this.subtype())
			throw new Error('content type is null');
		if (!this.role())
			throw new Error('role is null');
		if (this.message_uuid() === undefined)
			throw new Error('message_uuid is undefined');
		const formData = new FormData();
		const rs = new Blob([this.body()], {type: `${this.type()}/${this.subtype()}`});
		formData.append(this.role(), rs, this.name());

		let url = `/api/messages/message/content?message_uuid=${this.message_uuid()}`;

		if (this.content_nr() !== undefined)
			url += `&content_nr=${this.content_nr()}`;
		let request = await fetch(url, {
			method: 'POST',
			body: formData
		});

		let data = await request.json();

		if (data.results.length > 0)
		{
			let res = data.results[0];

			this.content_id(res.property.content_id);
			this.content_nr(res.content_nr);
			this.name(res.name);
		}

		if (data.status == 'ERROR')
			throw new Error(data.message || data.error || data.status)

		return data.results
	}
}

export default MessageContent;