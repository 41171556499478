
// COMPONENTS
import fml from './folder-message-list/folder-message-list.js';
import genmsgli from './message-li-view/message-li-view.js';
import msgaddress from './message-address.js';
import conversation from './conversation/conversation.js';

export default [
	fml,
	genmsgli,
	msgaddress,
	conversation
];
