

/**
 */
export default {
	name: 'Channels',
	options: {
		table: 'v_channels',
		schema: 'messages',
		sortfield: 'name'
	}
};
