

class ChannelList
{
	constructor()
	{
	}

	static get()
	{
		return new ChannelList();
	}
}

export default ChannelList;
