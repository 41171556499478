

import template from './edit-channel.html';

/**
 * View Model
 */
class EditChannelDialogViewModel {
	/**
	 * ViewModel constructor.
	 */
	constructor(dialog) {
		this.dialog = dialog;

		this.name = ko.observable(null);

		/* Driver settings */
		this.inputs = ko.observable({});
		this.fields = ko.observableArray([]);
		this.initialValue = ko.observable();
		this.active = ko.observable();

		this.drivers = ko.observableArray([]);
		this.selectedDriver = ko.observable(null);
		this.selectedDriver.subscribe((nv) => {
			this.fields(nv?.ui_params || []);
		});
		this.selectedInbox = ko.observable(null);
		this.selectedOutbox = ko.observable(null);
		this.selectedLocation = ko.observable(null);
		this.folders = ko.observableArray([]);
		this.contentLocations = ko.observableArray();
		this.active = ko.observable(true);
		this.default_from_address = ko.observable('');
	}

	async load(channel_name)
	{
		let result = await Grape.fetches.getJSON(`/api/record`, {schema: 'messages', table: 'v_drivers'});
		if (!result.records)
			throw new Error('Unable to fetch data');
		this.drivers(result.records);

		result = await Grape.fetches.getJSON(`/api/record`, {schema: 'messages', table: 'v_folders'});
		if (!result.records)
			throw new Error('Unable to fetch data');
		this.folders(result.records);

		result = await Grape.fetches.getJSON(`/api/messages/lookup/content-locations`);
		if (!result.names)
			throw new Error('Unable to fetch data');
		this.contentLocations(result.names);


		if (channel_name)
		{
			result = await Grape.fetches.getJSON(`/api/messages/channel`, {name: channel_name});
			if (!result)
				throw new Error('Unable to fetch data');

			console.log('result=',result);
			this.name(channel_name);
			this.initialValue(result.driver_settings);
			this.selectedDriver(this.drivers().find((x) => x.name == result.driver_name));
			this.selectedInbox(result.inbox_folder);
			this.selectedOutbox(result.outbox_folder);
			this.selectedLocation(result.content_location);
			this.default_from_address(result.default_from_address || '');
		}
	}

	/**
	 * Handle click event for save button
	 */
	async btnSave_click() {
		let driver_settings = {};
		for (let [name, $input] of Object.entries(this.inputs()))
			driver_settings[name] = ($input)();
		console.log('CONFIG=',driver_settings);

		const obj = {
			name: this.name(),
			driver_name: this.selectedDriver().name,
			inbox_folder: this.selectedInbox(),
			outbox_folder: this.selectedOutbox(),
			content_location: this.selectedLocation(),
			default_from_address: this.default_from_address(),
			active: this.active(),
			driver_settings: driver_settings
		};
		const result = await Grape.fetches.postJSON('/api/messages/channel', obj);
		await Grape.alerts.alert({message: 'Saved'});
		this.dialog.close();
	}

	/**
	 * Handle click event for close button
	 */
	btnClose_click() {
		this.dialog.close(false);
	}
}

/**
 * EditChannelDialog
 */
class EditChannelDialogClass {
	/**
	 * constructor
	 * @param {Object} bindings -
	 */
	constructor(bindings, element) {
		this.bindings = bindings;
		this.element = element;

		this.viewModel = new EditChannelDialogViewModel(this); // Name of the ViewModel defined above
	}

	get name() { return 'EditChannelDialog'; }

	async init() {
		this.viewModel.load(this.bindings?.channel || null);
	}
}

export default {
	name: 'EditChannelDialog',
	provider: 'ps',
	dialog_class: EditChannelDialogClass,
	template: template
};
