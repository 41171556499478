
/**
 */
export default {
	name: 'MessageCategory',
	options: {
		table: 'v_lookup_value',
		schema: 'grape',
		sortfield: 'idx',
		filter: [{ field: 'lookup_name', operand: '=', value: 'MessageCategory' }]
	}
}
