import template from './user-email-list-dialog.html';

class UserEmailListDialogViewModel
{

	constructor (page)
	{
		this.page = page;
		this.title = ko.observable();
		this.action = ko.observable();
		this.name = ko.observable('');
		this.namespace = ko.observable('');
		this.extra_email_address = ko.observableArray();
		this.extraEmailList = ko.observableArray();

	}

	btn_add_click ()
	{
		this.extraEmailList([]);
		this.extra_email_address.push({value: ko.observable('')});
	}

	btn_remove_click (data)
	{
		this.extra_email_address.remove(data);
	}

	btn_close_click ()
	{
		this.page.close(false);
	}

	async btn_save_click ()
	{
		this.extra_email_address().forEach((data) => {
			this.extraEmailList().push(data.value());
		});

		let params = {
			name: this.name(),
			namespace: this.namespace(),
			all_emails: this.extraEmailList().toString()
		};

		if(this.action() === 'create')
		{
			let response = await Grape.fetches.postJSON('/api/messages/address', params);
			if (response)
				this.page.close(true);
		}
		else if(this.action() === 'edit')
		{
			let response = Grape.fetches.postJSON(`/api/messages/address/${this.page.bindings.initial_values.address_list_id}`, params);
			if (response)
				this.page.close(true);
		}
	}
}

class UserEmailListDialog
{
	constructor (bindings, elements)
	{
		this.bindings = bindings;
		this.elements = elements;
		this.viewModel = new UserEmailListDialogViewModel(this);
		this.name = 'UserEmailListDialog';
	}

	async init ()
	{

		if (!this.bindings.initial_values.address_list_id )
		{
			this.viewModel.title('Add Email List');
			this.viewModel.action('create');
		}
		else
		{
			this.viewModel.title('Edit Email List');
			this.viewModel.action('edit');
			this.viewModel.name(this.bindings.initial_values.name);
			this.viewModel.namespace(this.bindings.initial_values.namespace);
			this.bindings.initial_values.all_emails.forEach((s) => { this.viewModel.extra_email_address.push({ value: ko.observable(s)}); });
		}
	}

	async updateData ()
	{

	}

}

export default {
	name: 'UserEmailList',
	dialog_class: UserEmailListDialog,
	template: template,
	provider: "ps"
};
