
import Message from './Message';

class Folder
{
	constructor(obj)
	{
		this.name = ko.observable();
		this.fullname = ko.observable();

		this.messages = ko.observableArray();
		this.total = ko.observable();
		this.filters = ko.observableArray();
		this.filters.subscribe(() => {
			this.loadMessages({limit:5, offset:5*(0)});
		});

		this.fields = ko.observableArray([
			{
				name: 'channel'
			},
			{
				name: 'status'
			},
			{
				name: 'date_inserted'
			},
			{
				name: 'diver'
			}
		]);

		this.set(obj);
	}

	set(obj)
	{
		if (obj.name)
			this.name(obj.name);
		if (obj.fullname)
			this.fullname(obj.fullname);
	}

	async loadMessages(options={})
	{
		let getOptions = {
			limit: 5,
			offset: 0
		};
		if (options.hasOwnProperty('limit'))
			getOptions.limit = options.limit;

		if (options.hasOwnProperty('offset'))
			getOptions.offset = options.offset;
		
		getOptions = this.serializeOptions(getOptions);

		if (options.hasOwnProperty('msg_fields'))
			getOptions.msg_fields = options.msg_fields;
		else
			getOptions.msg_fields = ['address', 'property'];

		if (options.hasOwnProperty('msg_props'))
			getOptions.msg_props = options.msg_props;
		else
			getOptions.msg_props = ['subject', 'contentcount'];

		const result = await Grape.fetches.getJSON('/api/record', getOptions);
		if (result.status != 'OK')
			throw new Error(result.message);

		const messages = [];
		for (let f of result.records)
		{
			const message = new Message(f);
			messages.push(message);
		}
		this.total(result.total);
		this.messages(messages);
	}

	serializeOptions(data)
	{
		const filters = [];
		filters.push({field: 'folder_name', operator: '=', value: this.name()});

		for (let f of this.filters())
		{
			filters.push({field: f.field, operator: f.operator, value: f.value});
		}
		
		const options = {
			schema: 'messages',
			table: 'v_folder_conversations',
			filter: filters,
			limit: data.limit,
			offset: data.offset
		};
		//window.localStorage.setItem('crm.folder_conversations.list-options', JSON.stringify(options));

		return options;
	}
}

export default Folder;
