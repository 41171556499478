import template from './templates.html';

class TemplatesVM
{
	constructor (page)
	{
		this.page = page;
		this.templates = ko.observableArray([]);
		this.selectedChannel = ko.observable(null);
		this.txtAddress = ko.observable('');
		this.templateData = ko.observable('{}');
	}

	async load()
	{
		const result = await Grape.fetches.getJSON(`/api/record`, {schema: 'messages', table: 'v_templates'});

		const visibleTemplates = result.records.map(template => ({
			...template,
			showChannel: ko.observable(false)
		}));
		this.templates(visibleTemplates || []);
	}

	async btnCreate()
	{
		Grape.navigate(`/ui/messages/setup/edit-template`, {'create': true});
	}

	async btnEdit(template)
	{
		Grape.navigate(`/ui/messages/setup/edit-template?template_uuid=${template.template_uuid}`, {'create': false});
	}

	async sendTemplate(template)
	{
		if (!template.showChannel())
		{
			this.templates().forEach(x => {
				x.showChannel(false);
			});
			template.showChannel(true);
		}
		else
		{
			if (this.selectedChannel() == null || this.selectedChannel() == '')
			{
				Grape.alerts.alert({type: 'warning', title: 'Error', message: 'Please select a channel to send the template to!'});
				return;
			}

			if (this.txtAddress() == '' || this.txtAddress() == null)
			{
				Grape.alerts.alert({type: 'warning', title: 'Error', message: 'Please enter an address to send the template to!'});
				return;
			}

			try {
				JSON.parse(this.templateData())
			} catch(err) {
				Grape.alerts.alert({type: 'danger', title: 'Error', message: 'Error: Template data is not valid json'});
				return;
			}

			let response = await Grape.fetches.postJSON('/api/messages/template-sendout',
			{
				template_uuid: template.template_uuid,
				to_address: this.txtAddress(),
				channel: this.selectedChannel(),
				template_data: JSON.parse(this.templateData()),
				send_date: (new Date()).toISOString()
			});

			if (response)
				Grape.alerts.alert({type:'success', title: 'Success', message: 'Template sent!'});

			template.showChannel(false);
		}
	}

	cancelSend(template)
	{
		if (template.showChannel())
			template.showChannel(false);
	}
}

class TemplatesPage
{
	constructor (bindings, element, page)
	{
		this.viewModel = new TemplatesVM(this);
		this.bindings = bindings;
		document.title = 'Messages: Edit template';
	}

	async init ()
	{
		this.viewModel.load();
	}

	teardown ()
	{
	}
}

export default {
	route: '[/ui/messages/setup/]templates',
	page_class: TemplatesPage,
	template: template
}
