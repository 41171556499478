import template from './message-li-view.html';
import Message from '../../models/Message.js';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleComponent
 * @description Sample Component that shows the name value
 */
class ComponentViewModel
{
	constructor(params)
	{
		this.params = params;

		this.message = ko_helper.safe_observable(params.message);
	}

	async btnMessageEdit_click()
	{
		const result = await Grape.dialog.open('EditMessage', {
			message: this.message()
		});
	}
}

export default {
	name: 'message-li-view',
	viewModel: ComponentViewModel,
	module_type: 'ko',
	template: template
};
