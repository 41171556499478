
import Folder from './Folder';

class FolderList
{
	constructor(obj)
	{
		this.list = ko.observableArray();

		this.set(obj);
	}

	set(obj)
	{
	}

	async load()
	{
		const result = await Grape.fetches.getJSON('/api/messages/folders');
		if (result.status != 'OK')
		{
			throw new Error(result.message);
		}

		let folders = [];
		for (let f of result.folders)
		{
			const folder = new Folder(f);
			folders.push(folder);
		}
		this.list(folders);
	}

	get length()
	{
		return this.list.length;
	}

	[Symbol.iterator]()
	{
		const list = this.list();
		let i = 0;
		return {
			next: function() {
				if (count < list.length)
					return {
						value: list[i++],
						done: false
					};
				return {done: true};
			}
		}
	}
}


export default FolderList;
