import template from './folder-message-list.html';
import Message from '../../models/Message.js';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleFolderMessageList
 * @description Sample FolderMessageList that shows the name value
 */
class FolderMessageListViewModel
{
	constructor(params)
	{
		this.params = params;
		this.channels = ko.observableArray();
		this.selectedChannel = ko.observable();
		this.selectedChannel.subscribe((nv) => this.btnCreateMessage_click(nv) );

		this.load();

		this.menuCreateMsgDisplay = ko.observable(false);
		this.folder = ko_helper.safe_observable(params.folder);
		this.folder.subscribe((newValue) => {
			this.current_page_number(1);
		});

		this.sort_field = ko.observable('date_inserted');
		this.sort_order = ko.observable('ASC');
		this.page_size_options = ko.observableArray([5,10,20,50]);
		this.current_page_size = ko.observable();
		this.current_page_size.subscribe(async () => {
			this.reload();
		});

		this.current_page_number = ko.observable(1);
		this.page_count = ko.computed(() => {
			return Math.ceil(this.folder().total()/this.current_page_size());
		});
	}

	page_click(page_number)
	{
		this.current_page_number(page_number);
		this.reload();
	}

	channelMessageViewComponent(message)
	{
		// TODO Get component view from registry for a message
		// message.channel
		return 'message-li-view';
	}

	async btnCreateMessage_click(data)
	{
		const message = new Message();
		await message.set({
			channel: data.name || '',
			status: 'New'
		});
		//await message.create({ folder_name: this.folder().name() });
		let response = await Grape.dialog.open('EditMessage', {
			folder: this.folder(),
			message: message
		});
		if (!response)
			this.reload();
	}

	async btnMessageEdit_click(message)
	{
		let response = await Grape.dialog.open('EditMessage', {
			message,
			folder: this.folder()
		});
		if(!response)
			this.reload();
	}

	async viewConversation(conversation_uuid, parent){
		const route = "[/]ui/messages/folders/conversation";
		const element = document.querySelector(".ps-sidebar-content");
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);
		Grape.pages.load_page(page, element, {conversation_uuid}, {}, () => {});
	}

	async btnMessageDelete_click(message)
	{
		confirm = await Grape.alerts.confirm({
			type: 'danger',
			title: 'Delete Message',
			message:'Are you sure you want to delete this message?'
		});

		if (confirm)
			Grape.fetches.deleteJSON('/api/messages/message', {message_uuid:message.message_uuid});

		this.reload();
	}

	async btnMessageResend_click(message)
	{
		await message.send();
	}

	async reload()
	{
		this.folder().loadMessages({
			limit:this.current_page_size(),
			offset:this.current_page_size()*(this.current_page_number()-1),
			sort_field: this.sort_field(),
			sort_order: this.sort_order()
		});
	}

	async load()
	{
		const Channels = await Grape.cache.get('Channels');
		this.channels(Channels);
	}

	sort_column (column)
	{
		if (this.sort_field() === column)
			this.sort_order(this.sort_order() === 'ASC' ? 'DESC' : 'ASC');
		else
		{
			this.sort_field(column);
			this.sort_order('ASC');
		}
			
		this.reload();
	}

	dateFormate (value)
	{
		if (!value)
			return '';

		const date = new Date(value);
		const dateOptions = {
			year: 'numeric',
			month: 'long',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			hours: true
		};

		let formatDate = date.toLocaleDateString('en-US', dateOptions);
		return formatDate;
	}

}

export default {
	name: 'folder-message-list',
	viewModel: FolderMessageListViewModel,
	module_type: 'ko',
	template: template
};
