
import EditMessage from './edit-message/edit-message.js';
import EditChannel from './edit-channel/edit-channel.js';
import UserEmailList from './add-email-list/user-email-list-dialog.js';

export default [
	EditMessage,
	EditChannel,
	UserEmailList
];
