

/**
 */
export default {
	name: 'ChannelTypes',
	options: {
		table: 'v_drivers',
		schema: 'messages',
		sortfield: 'name',
		fields: ['name', 'address_type']
	}
};
