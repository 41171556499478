/**
 */
export default {
	name: 'Folders',
	options: {
		table: 'v_folders',
		schema: 'messages',
		sortfield: 'name',
		fields: ['name', 'fullname']
	}
};

