import ChannelList from './ChannelList.js';
import Conversation from './Conversation.js';
import Folder from './Folder.js';
import FolderList from './FolderList.js';
import Message from './Message.js';
import MessageContent from './MessageContent.js';
import PropertyList from './PropertyList.js';
import Template from './Template.js';

export default [
	{name:'message-manager.channelList', dataModel: ChannelList},
	{name:'message-manager.conversation', dataModel: Conversation},
	{name:'message-manager.folder', dataModel: Folder},
	{name:'message-manager.folderList', dataModel: FolderList},
	{name:'message-manager.message', dataModel: Message},
	{name:'message-manager.messageContent', dataModel: MessageContent},
	{name:'message-manager.propertyList', dataModel: PropertyList},
	{name:'message-manager.template', dataModel: Template},
];
