

class Template
{
	constructor(obj)
	{
		this.template_uuid = ko.observable();
		this.message_uuid = ko.observable();
		this.name = ko.observable();
		this.namespace = ko.observable();
		this.driver_name = ko.observable();
		this.idx = ko.observable();
		this.message_categories = ko.observableArray();
		this.description = ko.observable();

		if (obj)
			this.set(obj);
	}

	async set(obj)
	{
		if (obj.hasOwnProperty('template_uuid'))
			this.template_uuid(obj.template_uuid);
		if (obj.hasOwnProperty('message_uuid'))
			this.message_uuid(obj.message_uuid);

		if (obj.hasOwnProperty('name'))
			this.name(obj['name']);
		if (obj.hasOwnProperty('namespace'))
			this.namespace(obj['namespace']);
		if (obj.hasOwnProperty('driver_name'))
			this.driver_name(obj['driver_name']);
		if (obj.hasOwnProperty('idx'))
			this.idx(obj['idx']);
		if (obj.hasOwnProperty('message_categories') && Array.isArray(obj['message_categories']))
			this.message_categories(obj['message_categories']);
		if (obj.hasOwnProperty('description'))
			this.description(obj['description']);
	}

	async fetch()
	{
		const result = await Grape.tables.select({
			schema: 'messages',
			table: 'v_templates',
			filter: [{f: 'template_uuid', o: '=', v: this.template_uuid()}]
		});
		const template = result.records[0];
		if (!template)
			throw new Error('Couldnt find template');

		this.set(template);
	}

	async create(options={})
	{
		const obj = Object.assign({}, this.toJS(), options);
		const result = await Grape.fetches.postJSON('/api/messages/template', obj);

		this.template_uuid(result.template_uuid);

		return this.template_uuid();
	}

	async save()
	{
		if (this.template_uuid() == null)
			return this.create();
		else
			return this.update();
	}

	async update()
	{
		const obj = this.toJS();
		await Grape.fetches.postJSON(`/api/messages/template`, obj);
	}

	/**
	 * Send message
	 */
	async send(address, template_data)
	{
		const result = await Grape.fetches.postJSON('/api/messages/template-sendout',
		{
			template_uuid: this.template_uuid(),
			address: address,
			template_data: template_data
		});

		return result;
	}

	toJS()
	{
		const obj = {
			template_uuid: this.template_uuid(),
			message_uuid: this.message_uuid(),
			name: this.name(),
			namespace: this.namespace(),
			driver_name: this.driver_name(),
			idx: this.idx(),
			message_categories: this.message_categories(),
			description: this.description()
		};

		return obj;
	}
}

export default Template;
