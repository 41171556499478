import template from './email-user-list.html';

class EmailUserListViewModel
{
	constructor (page)
	{
		this.page = page;
		this.list = ko.observableArray([]);
	}

	async get_user_email_list ()
	{
		let params = {
			schema: 'messages',
			table: 'v_address_list',
			sortfield: 'address_list_id',
			limit: 5000
		};

		let results = await Grape.fetches.getJSON("/api/record", params);

		if (results)
		{
			if (results.result_count == 0 || results.records == undefined)
				results.records = [];
				
			this.list(results.records);
		}
	}

	async edit_email_list (data)
	{
		let bindings = {
			initial_values : {
				'address_list_id': data.address_list_id,
				'name': data.name,
				'namespace': data.namespace,
				'all_emails': data.all_emails,
				'action': 'edit'
			}
		};

		let response = await Grape.dialog.open('UserEmailList', bindings);

		if (response)
			this.get_user_email_list();

	}

	async btn_add_email_list_click ()
	{
		let bindings = {
			initial_values : {
				'action': 'create'
			}
		};

		let response = await Grape.dialog.open('UserEmailList', bindings);

		if (response)
			this.get_user_email_list();

	}

	btn_help_click ()
	{
		// TODO: Grape.show_help does not exist anymore
		// Grape.show_help('/reports/help/email_user_list_help.html');
		console.log('show help clicked');
	}
}

class EmailUserListPage
{
	constructor (bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new EmailUserListViewModel(this);
		this.name = 'EmailUserListPage';
	}

	async init ()
	{

	}

	updateData ()
	{
		this.viewModel.get_user_email_list();
	}
}

export default {
	route: '[/ui/messages/setup/]email_user_list',
	page_class: EmailUserListPage,
	template: template
};
