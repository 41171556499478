import template from './conversation.html';

class ConversationViewModel
{
	constructor(params)
	{
		// To reply us inReplyTo with reference
		this.params = params;
		this.conversation_uuid = ko_helper.safe_observable(params.conversation_uuid);
		this.messages = ko.observableArray();

		this.conversation_uuid.subscribe((val) => this.get_conversation(val) );
		this.init();
	}

	async init ()
	{
		await this.get_conversation();
	}

	async saveCallback ()
	{
		Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Message saved!' });

		this.collapse_all_messages();
		await this.get_conversation();
	}

	async sendCallback ()
	{
		Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Message sent!' });

		this.collapse_all_messages();
		await this.get_conversation();
	}

	async get_conversation ()
	{
		if (this.conversation_uuid())
		{
			let res = await Grape.fetches.getJSON(`/api/messages/conversation/${this.conversation_uuid()}`,
				{
					fields: [
						'property', 'messages', 'messages.property', 'messages.address', 'category',
						'messages.address-full', 'messages.content', 'messages.content.property'
					]
				}
			);

			//TODO remove reply/forward objects
			let messages = res.messages.map((x, index) => ko.observable({
				message: x,
				reply: this.get_message_obj(x, 'reply'),
				forward: this.get_message_obj(x, 'forward'),
				collapsed: ko.observable(index !== 0),
				subject: x.property.subject || ' - ',
				replying: ko.observable(false),
				forwarding: ko.observable(false)
			}));

			this.messages(messages);
		}
	}

	collapse_message (data)
	{
		data.collapsed(true);
		data.replying(false);
	}

	expand_message (data)
	{
		data.collapsed(false);
	}

	collapse_all_messages ()
	{
		this.messages().forEach(message => {
			message().collapsed(true);
			message().replying(false);
			message().forwarding(false);
		});
	}

	expand_all_messages ()
	{
		this.messages().forEach(message => {
			message().collapsed(false);
		});
	}

	btn_reply_message (data)
	{
		data.replying(true);
		data.forwarding(false);
		data.collapsed(false);
	}

	btn_forward_message (data)
	{
		data.replying(false);
		data.forwarding(true);
		data.collapsed(false);
	}

	get_message_obj (message, type)
	{
		let ret = {
			channel: message.channel,
			driver: message.driver,
			conversation_uuid: message.conversation_uuid,
			address: {
				to: message.address.from,
				from: message.address.to
			},
			property: {}
		};

		if (type === 'reply')
		{
			if (message.driver === 'Email')
				ret.property = { subject: `Re: ${message.property.subject}` };
			ret.property.inreplyto = message.property.uniquereference||message.message_uuid;
		}

		if (type === 'forward')
		{
			if (message.driver === 'Email')
				ret.property = { subject: `Fw: ${message.property.subject}` };
			ret.property.body = message.property.body;
		}

		return ret;
	}
}


export default {
	name: 'ko-conversation',
	viewModel: ConversationViewModel,
	module_type: 'ko',
	template: template
}
