
// CACHES
import Channels from './Channels.js';
import ChannelTypes from './ChannelTypes.js';
import Folders from './Folders.js';
import MessageAddressRole from './MessageAddressRole.js';
import MessageCategory from './MessageCategory.js';

export default [
	Channels,
	ChannelTypes,
	Folders,
	MessageAddressRole,
	MessageCategory
];
