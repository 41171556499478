

class Conversation
{
	constructor(obj)
	{
		this.conversation_uuid = ko.observable();
		this.date_inserted = ko.observable();
		this.status = ko.observable();
		this.tags = ko.observableArray();
		this.propertyList = ko.observableArray();
		this.messageList = ko.observableArray();
		this.addressList = ko.observableArray();

		if (obj)
			this.set(obj);
	}

	async set(obj)
	{
		if (obj.hasOwnProperty('conversation_uuid'))
			this.conversation_uuid(obj.conversation_uuid);
		if (obj.hasOwnProperty('address'))
		{
			const list = [];
			if (Array.isArray(obj.address))
			{
				for (let i of obj.address)
				{
					list.push({
						role: i.role,
						name: ko.observable(i.name)
					});
				}
			}
			else if (obj.address instanceof Object) /* Object with role as key */
			{
				for (let [k, v] of Object.entries(obj.address))
				{
					if (Array.isArray(v))
						for (let i of v)
							list.push({role: k, name: ko.observable(i)});
					else
						list.push({role: k, name: ko.observable(v)});
				}
			}
			this.addressList(list);
		}
		if (obj.hasOwnProperty('status'))
			this.status(obj.status);
		if (obj.hasOwnProperty('property'))
		{
			const list = [];
			const names = [];
			for (let [k, v] of Object.entries(obj.property))
			{
				list.push({name: k, value: ko.observable(v)});
				names.push(k);
			}
			for (let item of this.propertyList() || [])
				if (names.indexOf(item.name) == -1)
					list.push(item);
			this.propertyList(list);
		}
	}

	/**
	 * Get property value observable
	 */
	getPropertyObservable(name)
	{
		const prop = this.getProperty(name);
		return prop.value;
	}

	/**
	 * Get property value
	 */
	getPropertyValue(name)
	{
		const prop = this.getProperty(name);
		return prop.value();
	}


	/**
	 * Get property entry, will create one if it does not exist
	 */
	getProperty(name)
	{
		for (let item of this.propertyList() || [])
			if (item.name == name)
				return item;
		const item = {
			name: name,
			value: ko.observable()
		};
		this.propertyList.push(item);
		return item;
	}

	/**
	 * Set property value
	 */
	setPropertyValue(name, value)
	{
		const prop = this.getProperty(name);
		prop.value(value);
		return prop;
	}


	async fetch(fields)
	{
		const result = await Grape.fetches.getJSON(`/api/messages/conversation/${this.conversation_uuid()}`, {fields});
		console.log('conversation =', result);

		this.set(result);
	}

	async create(options={})
	{
		const obj = Object.assign({}, this.toJS(), options);
		const result = await Grape.fetches.postJSON('/api/messages/conversation', obj);
		console.log(result);
		this.conversation_uuid(result.conversation_uuid);
		return this.conversation_uuid();
	}

	async save()
	{
		if (this.conversation_uuid() == null)
			return this.create();
		else
			return this.update();
	}

	async update()
	{
		const obj = this.toJS();
		const result = await Grape.fetches.postJSON(`/api/messages/conversation/${this.conversation_uuid()}`, obj);
		console.log(result);
	}

	toJS()
	{
		const property = {};
		for (let item of this.propertyList() || [])
			property[item.name] = item.value();
		
		const address = {};
		for (let item of this.addressList() || [])
		{
			if (!address[item.role])
					address[item.role] = [];
			address[item.role] = item.name();
		}

		const obj = {
			conversation_uuid: this.conversation_uuid(),
			date_inserted: this.date_inserted(),
			status: this.status(),
			address: address,
			property: property
		};
		return obj;
	}
}

export default Conversation;

