import template from './folder-message-list.html';
import Message from '../../models/Message.js';

/**
 * This is an example knockout component class. Note the export section at the end of the file.
 * @kind component
 * @class SampleFolderMessageList
 * @description Sample FolderMessageList that shows the name value
 */
class FolderMessageListViewModel
{
	constructor(params)
	{
		this.params = params;
		this.channels = ko.observableArray();
		this.selectedChannel = ko.observable();
		this.selectedChannel.subscribe((nv) => this.btnCreateMessage_click(nv) );

		this.load();

		this.menuCreateMsgDisplay = ko.observable(false);
		this.folder = ko_helper.safe_observable(params.folder);
		this.folder.subscribe((newValue) => {
			this.current_page_number(1);
		});

		this.current_page_number = ko.observable(1);
		this.page_count = ko.computed(() => {
			return Math.ceil(this.folder().total()/5);
		});
	}

	page_click(page_number)
	{
		this.current_page_number(page_number);
		this.folder().loadMessages({limit: 5, offset: 5*(page_number-1)});
	}

	channelMessageViewComponent(message)
	{
		// TODO Get component view from registry for a message
		// message.channel
		return 'message-li-view';
	}

	async btnCreateMessage_click(data)
	{
		const message = new Message();
		await message.set({
			channel: data.name || '',
			status: 'New'
		});
		//await message.create({ folder_name: this.folder().name() });
		let response = await Grape.dialog.open('EditMessage', {
			folder: this.folder(),
			message: message
		});
		if (!response)
			this.folder().loadMessages({limit:5, offset:5*(this.current_page_number()-1)});
	}

	async btnMessageEdit_click(message)
	{
		let response = await Grape.dialog.open('EditMessage', {
			message
		});
		if(!response)
			this.folder().loadMessages({limit:5, offset:5*(this.current_page_number()-1)});
	}

	async btnMessageDelete_click(message)
	{
		confirm = await Grape.alerts.confirm({
			type: 'danger',
			title: 'Delete Message',
			message:'Are you sure you want to delete this message?'
		});

		if (confirm)
			Grape.fetches.deleteJSON('/api/messages/message', {message_uuid:message.message_uuid});

		this.folder().loadMessages({limit:5, offset:5*(this.current_page_number()-1)});
	}

	async btnMessageResend_click(message)
	{
		await message.send();
	}

	async load()
	{
		const Channels = await Grape.cache.get('Channels');
		this.channels(Channels);
	}

}

export default {
	name: 'folder-message-list',
	viewModel: FolderMessageListViewModel,
	module_type: 'ko',
	template: template
};
